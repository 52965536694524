
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 流量卡购买记录
 */
export default defineComponent({
  name: 'SimCardOrder',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'channel',
              label: '渠道',
              type: 'select',
              options: mapper?.device.backerNo
            },
            {
              field: 'channelOrderno',
              label: '渠道单号'
            },
            {
              field: 'channelSn',
              label: '机具号'
            }
          ]
        },
        remote: {
          action: https?.market.pageSimCardOrder
        }
      },
      cols: [
        {
          field: 'orderno',
          label: '支付单号',
          ability: 'copy',
          width: 240
        },
        {
          field: 'channelOrderno',
          label: '渠道单号'
        },
        {
          field: 'channel',
          label: '渠道',
          options: mapper?.device.backerNo,
          width: 120
        },
        {
          field: 'channelMchId',
          label: '渠道商户ID'
        },
        {
          field: 'channelMchName',
          label: '渠道商户名'
        },
        {
          field: 'amount',
          label: '总返现金额',
          width: 120
        },
        {
          field: 'topAgentProfit',
          label: '一代返现金额',
          width: 120
        },
        {
          field: 'channelSn',
          label: '机具号',
          width: 160
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.market.simCardOrderStatus,
          width: 120
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'createTime',
          label: '创建时间',
        }
      ]
    };

    return { tableOpts };
  }
});
